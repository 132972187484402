import { React, useState, useEffect } from 'react';
import api from '../api';
import AdminNewsAdd from './AdminNewsAdd';
import AdminNewsPut from './AdminNewsPut';
import { Button, Select, Modal } from "antd";
import { ExclamationCircleFilled } from '@ant-design/icons';

/**
 * @brief Iterate JSON object with titles and generate data for news selection menu
 * @param {JSON} data 
 * @returns a list of json 
 */
function selectOptions(data) {
    const options = [];
    for (let i = 0; i < data.length; i++) {
        const newsTitle = {
            value: i,
            label: data[i].title_sv
        };
        options.push(newsTitle);
    }

    return options;
}

const AdminNews = ({ onLogout }) => {
    // Handle news
    const [key, setKey] = useState(0); // To force re-draw modify screen
    const [newsTitles, setNewsTitles] = useState([]);
    const [newsData, setNewsData] = useState([]);
    const [selected, setSelected] = useState(0);

    // Either get a selected entry of a default empty entry
    function getCurrentNews() {
        if (newsData.length == 0) return {id: 0, title_sv: "", title_en: "", text_sv: "", text_en: "", published: "1990-09-12"};
        else return newsData[selected];
    }

    const handleChange = (value) => {
        setSelected(value);
        setKey(prevkey => prevkey + 1); 
    };
    // Used for confirming delete operation
    const { confirm } = Modal;

    // Fetch all news titles
    useEffect(() => {
        // Get news titles and set them to selection menu
        api.get('NEWS') 
            .then(response => {
                const options = selectOptions(response.data);
                setNewsTitles(options);
                setNewsData(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    // Handle CRUD
    const [addOpen, setAddOpen] = useState(false);
    const [putOpen, setPutOpen] = useState(false);

    const [deleteOpen, setDeleteOpen] = useState(false);

    const showAdd = () => { setAddOpen(true); };
    const closeAdd = () => { setAddOpen(false); };

    const showPut = () => { setPutOpen(true); };
    const closePut = () => { setPutOpen(false); };

    /**
     * @brief Used for deleting a news entry
     * @returns 
     */
    const showConfirmDeletion = () => {
        if (newsTitles.length === 0) return;

        confirm({
            title: `Vill du verkligen ta bort ${newsData[selected].title_sv}?`,
            icon: <ExclamationCircleFilled />,
            content: 'Obs! Åtgärden går inte att ångra!',
            cancelText: 'Avbryt',
            okText: 'Ja',
            okType: 'danger',
            onOk() {
                // Get news titles and set them to selection menu
                api.delete('NEWS/' + newsData[selected].id) 
                    .then(response => {
                        window.location.reload();
                    })
                    .catch(error => {
                        console.log(error);
                        onLogout();
                    });
            },
            onCancel() {
                
            },
        });
    };

    return (
        <>
        <h1>Nyheter</h1>
        <Select
            defaultValue={0}
            style={{
                width: "50%",
                textAlign: "center",
            }}
            onChange={handleChange}
            options={newsTitles}
        />
        <Button className='admin__main-button' onClick={showAdd}>Lägg till ny</Button>
        <AdminNewsAdd isModalOpen={addOpen} closeModal={closeAdd} onLogout={onLogout} />
        <Button className='admin__main-button' onClick={showPut}>Redigera</Button>
        <AdminNewsPut key={"news_put_form" + key} isModalOpen={putOpen} closeModal={closePut} onGetCurrentNews={getCurrentNews} onLogout={onLogout} />
        <Button className='admin__main-button' onClick={showConfirmDeletion}>Ta bort</Button>
        </>
    );
};

export default AdminNews;