/**
 * @brief Get a cookie 
 * @param cname Cookie name
 * @returns A cookie value
 */
export function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

/**
 * @brief Sets a cookie value 
 * @param cname Cookie name
 * @param cvalue Cookie value
 * @param exdays How many days before it expires
 */
export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/**
 * @brief Removes all cookies by setting its expire date to expire
 */
export function resetCookies() {
  // Get all cookies
  let cookies = document.cookie.split(";");

  // Loop through each cookie
  for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      // Split cookie string into name and value
      let eqPos = cookie.indexOf("=");
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      // Remove any leading spaces
      name = name.replace(/^\s*/, "");
      
      // Set the expiration date to a time in the past to delete it
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
}
