import { React } from 'react';
import { HashLink } from 'react-router-hash-link';
// Styling
import './home-about.css';

// Assets
import { BiChevronRight } from "react-icons/bi";
import HomeOmOss from '../../../assets/home-omoss.jpg'; 

let text = require('./text.json');

// Background cover image
var homeOmOss = {  
    backgroundImage: `url(${HomeOmOss})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%', // Or a specific width like '300px'
    height: '100%', // Or a specific height like '300px'
};

/// About view on home page
const HomeAbout = ({ language }) => (
    <div className='home__about' style={homeOmOss}>    
    <div className='home__about-overlay'>
        <div className='home__about-content'>
            <h1> {text[language]["about-mini-title"]} </h1>
            <div className='home__about-content-text'>
                <h1>{text[language]["about-mini-h1"]}</h1>
                <p id='home__about-content-small'>{text[language]["about-mini-small"]}</p>
                <p id='home__about-content-big'>{text[language]["about-mini-big"]}</p>

            </div>
            <HashLink to="/about#">
                <span>{text[language]["read-more"]}</span>
                <BiChevronRight color='white' size={28} />
            </HashLink>
        </div>
    </div>
    </div>
);

export default HomeAbout;