const scrollIntoView = (id) => {
    const element = document.getElementById(id); 
    const headerOffset = 100;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;
    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
   });
}

export default scrollIntoView;