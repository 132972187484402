import React, { useEffect, useRef } from 'react';
import './sidebar.css';
import { HashLink } from 'react-router-hash-link';

let text = require('./text.json');

/// States for the language bar and the side bar
const BarStates = Object.freeze({
    DEACTIVATED: 0, // non-visable
    DEACTIVATED_ANIM: 1, // moving inwards and becoming non-visable
    ACTIVATED: 2, // visable
    ACTIVATED_ANIM: 3 // moving outwards and becoming visable
});

/// Toggle the language bar depending on the state (BarStates)
function toggleSidebar(state) {
    let SidebarClass;
    if (state === BarStates.ACTIVATED) {
        SidebarClass = 'sidebar__contents';
    }
    if (state === BarStates.ACTIVATED_ANIM) {
        SidebarClass = 'sidebar__contents scale-in-hor-right';
    }
    if (state === BarStates.DEACTIVATED) {
        SidebarClass = 'sidebar__contents-off';
    }
    if (state === BarStates.DEACTIVATED_ANIM) {
        SidebarClass = 'sidebar__contents scale-out-hor-right';
    }
  
    return SidebarClass;
}

const Sidebar = ({ language, onSetSidebar, sidebarStatus }) => {
    const ref = useRef(null);

    let sidebarClass = toggleSidebar(sidebarStatus);

    // If we click outside of the sidebar - close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Ignore this event if the side bar is not open
            if (sidebarStatus === BarStates.DEACTIVATED || sidebarStatus === BarStates.DEACTIVATED_ANIM) return;

            if (ref.current && !ref.current.contains(event.target)) {
                onSetSidebar && onSetSidebar();
            }
        };
    
        document.addEventListener('click', handleClickOutside, true);
    
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
      }, [sidebarStatus, onSetSidebar]);
    

    return (
        <div className={sidebarClass} ref={ref}>
            <HashLink to="/#" onClick={onSetSidebar}>{text[language]["sidebar-home"]}</HashLink>
            <hr />
            <HashLink to="/offer#" onClick={onSetSidebar}>{text[language]["sidebar-offer"]}</HashLink>
            <div className='sidebar__contents-submenu'>
                <HashLink smooth to="/offer#offer__service" onClick={onSetSidebar}>{text[language]["sidebar-offer-1"]}</HashLink>
                <HashLink smooth to="/offer#offer__contracts" onClick={onSetSidebar}>{text[language]["sidebar-offer-2"]}</HashLink>
                <HashLink smooth to="/offer#offer__lights" onClick={onSetSidebar}>{text[language]["sidebar-offer-3"]}</HashLink>
                <HashLink smooth to="/offer#offer__tech" onClick={onSetSidebar}>{text[language]["sidebar-offer-4"]}</HashLink>
                <HashLink smooth to="/offer#offer__form" onClick={onSetSidebar}>{text[language]["sidebar-offer-5"]}</HashLink>
            </div>
            <hr />
            <HashLink to="/about#" onClick={onSetSidebar}>{text[language]["sidebar-aboutus"]}</HashLink>
            <div className='sidebar__contents-submenu'>
                <HashLink smooth to="/about#about__video" onClick={onSetSidebar}>{text[language]["sidebar-aboutus-1"]}</HashLink>
                <HashLink smooth to="/about#about__info" onClick={onSetSidebar}>{text[language]["sidebar-aboutus-2"]}</HashLink>
                <HashLink smooth to="/about#about__ref" onClick={onSetSidebar}>{text[language]["sidebar-aboutus-3"]}</HashLink>
            </div>
            <hr />
            <HashLink to="/work#" onClick={onSetSidebar}>{text[language]["sidebar-jobs"]}</HashLink>
            <div className='sidebar__contents-submenu'>
                <HashLink smooth to="/work#" onClick={onSetSidebar}>{text[language]["sidebar-jobs-1"]}</HashLink>
                <HashLink smooth to="/work#work__available" onClick={onSetSidebar}>{text[language]["sidebar-jobs-2"]}</HashLink>
            </div>
            <hr />
            <HashLink to="/contact#" onClick={onSetSidebar}>{text[language]["sidebar-contact"]}</HashLink>
            <div className='sidebar__contents-submenu'>
                <HashLink smooth to="/contact#contact__office" onClick={onSetSidebar}>{text[language]["sidebar-contact-1"]}</HashLink>
                <HashLink smooth to="/contact#contact__form" onClick={onSetSidebar}>{text[language]["sidebar-contact-2"]}</HashLink>
                <HashLink smooth to="/contact#contact__people" onClick={onSetSidebar}>{text[language]["sidebar-contact-3"]}</HashLink>
                <HashLink smooth to="/contact#contact__visit" onClick={onSetSidebar}>{text[language]["sidebar-contact-4"]}</HashLink>
            </div>

        </div>
    )
}

export default Sidebar;