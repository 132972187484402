import { React, useState} from 'react';
import { Button, Modal, Form, Input } from "antd";
import api from '../api';

const BETWEEN_LOGIN_TIME = 2000; // 2 seconds between each try

const RequestPassword = ({ closeModal, isOpen }) => {
    const [form] = Form.useForm();
    const [loginTime, setLoginTime] = useState(Date.now());
    
    async function submitRequest() {
        const { username, email } = form.getFieldsValue();
        const sendPackage = JSON.stringify({ username, email });
    
        console.log(sendPackage);

        api.post('RESTORE_PASSWORD/', sendPackage)
        .then(response => {
            window.location.reload();
            form.resetFields();
            closeModal();
        })
        .catch(error => {
            form.resetFields();
        });
    }

    const handleOk = () => {
        if (form.getFieldValue('check') != "Joakim Bogfelt") return;
        // Force the user to wait 2 seconds between each send
        if ((Date.now() - loginTime) < BETWEEN_LOGIN_TIME) return;
        setLoginTime(Date.now());
        // Check for errors
        const errors = form.getFieldsError();
        let canSubmit = true;
        errors.forEach((e) => {
            if (e.errors.length > 0) canSubmit = false;
            if (form.getFieldValue(e.name[0]) == undefined) canSubmit = false;
        })

        // If we can submit then submit
        if (canSubmit) {
            submitRequest();
        }
    };

    const handleCancel = () => {
        form.resetFields();
        closeModal();
    };

    return (
        <Modal 
            title="Återställ lösenord"
            open={isOpen}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                  Avbryt
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                  Slutför
                </Button>,
              ]}
        >

        <Form
            form={form}
            labelCol={{
            span: 7,
            }}
            wrapperCol={{
            span: 16,
            }}
            layout="horizontal"
            style={{
            maxWidth: 800,
            }}
        >
            <p> Vad heter företagets VD? </p>
            <br/>
            <Form.Item
                label="Svar"
                name="check"
                rules={[
                    {pattern: new RegExp(/^[a-zA-Z0-9_ ]{6,20}$/),message: "Minst sex tecken!"},
                    {
                    required: true,
                    message: 'Var god fyll!',
                    },
                ]}
                >
                <Input />
            </Form.Item>

            <Form.Item
                label="Användarnamn"
                name="username"
                rules={[
                    {pattern: new RegExp(/^[a-zA-Z0-9_]{6,20}$/),message: "Minst sex tecken!"},
                    {
                    required: true,
                    message: 'Var god fyll i ditt namn!',
                    },
                ]}
                >
                <Input />
            </Form.Item>

            <Form.Item
                label="E-mail"
                name="email"
                rules={[
                    {pattern: new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),message: "exempel: namn@domän.se"},
                    {
                    required: true,
                    message: 'Var god fyll i e-mail-address!',
                    },
                ]}
                >
                <Input />
            </Form.Item>

        </Form>
        </Modal>
    );
    
}

export default RequestPassword;