import { React } from 'react';
import api from '../api';
import { Form, Input, Button } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CRUDadd from '../CRUDadd';
const { TextArea } = Input;

const AdminWorkPut = ({ isModalOpen, closeModal, onGetCurrentWork, onLogout }) => {

    const currentValue = onGetCurrentWork();

    async function submitData(values) {
        values.id = currentValue.id;
        // Serialize arrays to strings
        values.locations = values.locations.join();
        values.requirements = values.requirements.join();
        values.merits = values.merits.join();
        const job = JSON.stringify(values);
      
        api.put('WORK/', job)
          .then(response => {
            window.location.reload();
          })
          .catch(error => {
            console.error(error);
            onLogout();
          });
      }

    const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 4,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 24,
          },
        },
    };
    const formItemLayoutWithOutLabel = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 24,
            offset: 4,
          },
        },
    };

    /**
     * @brief A list where you can add or remove items
     * @param {string} name 
     * @param {string} title 
     * @param {string} addText 
     * @param {string} placeholder 
     * @param {string} messageText 
     * @param {string} promiseText 
     * @returns 
     */
    const addableList = (name, title, addText, placeholder, messageText, promiseText, initial) => (
        
        <Form.List
            name={name}
            initialValue={initial.split(",")}
            rules={[
            {
                validator: async (_, names) => {
                if (!names || names.length < 1) {
                    return Promise.reject(new Error({promiseText}));
                }
                },
            },
            ]}
        >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? title : ''}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: messageText,
                    },
                  ]}
                  noStyle
                >
                  <Input
                    placeholder={placeholder}
                    style={{
                      width: '60%',
                    }}
                  />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{
                  width: '60%',
                }}
                icon={<PlusOutlined />}
              >
                {addText}
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    );

    const items = (
        <>
        <Form.Item name="title" label="Titel" initialValue={currentValue.title} 
            rules={[{pattern: new RegExp(/^[ \-!?':,.üÜåäöÅÄÖa-zA-Z0-9]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item>
        {addableList("locations", "Ort", "Lägg till ort", "ortsnamn", "Ange eller ta bort ort!", "Minst en ort!", currentValue.locations)}
        {addableList("requirements", "Krav", "Lägg till krav", "kravbeskrivning", "Ange eller ta bort krav!", "Minst ett krav!", currentValue.requirements)}
        {addableList("merits", "Meriterande", "Lägg till merit", "meritbeskrivning", "Ange eller ta bort merit!", "Minst en merit!", currentValue.merits)}
        <Form.Item name="info" label="Beskrivning" initialValue={currentValue.info} 
            rules={[{pattern: new RegExp(/^[ \-!?':,.éÉåäöÅÄÖa-zA-Z0-9\n]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <TextArea rows={4} />
        </Form.Item>
        
        </>
    );

    return (<CRUDadd isModalOpen={isModalOpen} closeModal={closeModal} handleSubmitData={submitData} title={"Lägg till ny medarbetare"} items={items} />);
};

export default AdminWorkPut;