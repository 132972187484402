import { React, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import api from './api';
import AdminNews from './news/AdminNews';
import AdminWork from './work/AdminWork';
import AdminEmployees from './employees/AdminEmployees';
import AdminCustomers from './customers/AdminCustomers';
import ChangePassword from './passwordHandling/ChangePassword';
import { Button } from 'antd';
import './admin.css';

const Admin = (language) => {
    const navigate = useNavigate();
    const username = JSON.parse(sessionStorage.getItem("username"));
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const loggedInVal = sessionStorage.getItem("loggedIn");
        
        if (!loggedInVal) {
            console.log("Navigates!");
            navigate("/login");
        }
        setLoggedIn(loggedInVal);
    }, [navigate]);

    function handleLogout() {
        const data = {username: username};
        api.post('LOGOUT/', JSON.stringify(data))
        .then(response => {
            console.log("Logged out!");
        })
        .catch(error => {
            console.log("Error logging out: " + error);
        }).finally( () => {
            sessionStorage.clear();
            navigate("/login");
        })
        
    }

    // Open and close new password view
    const [isChangePasswordOpen, setChangePasswordOpen] = useState(false);
    const openChangePassword = () => { setChangePasswordOpen(true); }
    const closeChangePassword = () => { setChangePasswordOpen(false); }

    return (
        <>
        {loggedIn && (
            <div className="admin__main">
                <ChangePassword closeModal={closeChangePassword} isOpen={isChangePasswordOpen} username={username} />
                <div className="admin__main-topbar">
                    <h1>Administration</h1>
                    <h1>Inloggad: {username}</h1>
                    <div className='admin__main-topbar__buttons'>
                        <Button onClick={openChangePassword}> Byt Lösenord </Button>
                        <Button onClick={handleLogout}> Logga Ut </Button>
                    </div>
                </div>
                <div className="admin__main-body">

                    <div className='admin__main-body-row'>
                        <div className='admin__main-body-item'>
                            <AdminEmployees onLogout={handleLogout} />
                        </div>
                        <div className='admin__main-body-item'>
                            <AdminNews onLogout={handleLogout} />
                        </div>
                    </div>

                    <div className='admin__main-body-row'>
                        <div className='admin__main-body-item'>
                            <AdminWork onLogout={handleLogout} />
                        </div>
                        <div className='admin__main-body-item'>
                            <AdminCustomers onLogout={handleLogout} />
                        </div>
                    </div>

                </div>
            </div>
        )}
        </>
    );
}

export default Admin;