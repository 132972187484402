import React from 'react';
import './news.css';
import NewsSwiperContainer from './NewsSwiperContainer'

let text = require('./text.json');

/// News component
const News = ({ language }) => {
    // Get all new slides
    return (
        <div className='news__view'>
            <h1>{text[language]["news-header"]}</h1>
            <div className='news__container'>
                <NewsSwiperContainer language={language} />
            </div>
        </div>
    )
}

export default News;
