
import React, { useState, useEffect }  from 'react';
import { Checkbox } from 'antd';
import './form.css';

const SecureButton = ({ onAccept, color, language, align }) => {
    const [buttonEnabled, setButtonEnabled] = useState(false);

    const agreement = (language === 'sv')
    ? "Genom att godkänna accepterar du att uppgifterna du skrivit in sparas hos oss."
    : "By approving, you agree that the data you entered will be stored with us.";
    const buttonText = (language === 'sv') 
    ? "Godkänn"
    : "Approve";

    const handleClick = (e) => {
        if (buttonEnabled) onAccept(e.target.checked);
    }

    useEffect(() => {
        const handleInteraction = () => {
            setButtonEnabled(true);
            document.removeEventListener('mousemove', handleInteraction);
            document.removeEventListener('touchmove', handleInteraction);
            document.removeEventListener('touchstart', handleInteraction);
        };

        document.addEventListener('mousemove', handleInteraction);
        document.addEventListener('touchmove', handleInteraction);
        document.addEventListener('touchstart', handleInteraction);

        return () => {
            document.removeEventListener('mousemove', handleInteraction);
            document.removeEventListener('touchmove', handleInteraction);
            document.removeEventListener('touchstart', handleInteraction);
        };
    }, []);

    return (
        <>
            <div className='form__secure-button' style={{flexDirection: align}}>
                <p style={{color: color}}> {agreement} </p>
                <Checkbox color={color} defaultChecked={false}
                    onChange={handleClick}
                > 
                    {buttonText} 
                </Checkbox>
            </div>
        </>
    )
}

export default SecureButton;