import React, { useState }  from 'react';
import { useForm, Controller } from "react-hook-form"
import { Select, Input, message, Radio } from "antd";
import SecureButton from '../../form/SecureButton';
// Styling
import '../../form/form.css';
import './offer.css';

let text = require('./text.json');
let textForm = require('../../form/text.json');
const { TextArea } = Input;

const BETWEEN_SEND_TIME = 2000; // 2 seconds between each try

// Displays error message if form field input is wrong
const errorMessage = (error) => (
    <p className='form__error-message'>{error && ('(' + error.message + ')')} </p>
);

const OfferForm = ({ language, category, landing }) => {
    // Used for the form
    const { reset, handleSubmit, control, formState: { errors } } = useForm();
    // Used for pop-up message when applying for job
    const [messageApi, contextHolder] = message.useMessage();
    const [sendTime, setSendTime] = useState(Date.now());
    
    // For accepting the terms
    const [accept, setAccept] = useState(false);
    const onAccept = (value) => { setAccept(value); }

    const availableErrands = category;

    // Submit button action
    async function onSubmit(data) {
        if (!accept) return;
        if ((Date.now() - sendTime) < BETWEEN_SEND_TIME) return; // Mimimum time to wait between each send to avoid spamming

        const url = process.env.REACT_APP_PHP_CONTACTFORM;

        const errand = data['errandOption'];

        // Create a new FormData object to send the data
        const formData = new FormData();
        formData.append('errand', availableErrands[errand].label);
        formData.append('company', data['company']);
        formData.append('name', data['name']);
        formData.append('phone', data['phone']);
        formData.append('email', data['email']);
        formData.append('text', data['message']);
        formData.append('call_type', data['callType']);

        // Send the request using the Fetch API
        await fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
        .then(response => {
            submitMessage(data, true);
            setSendTime(Date.now());
        })
        .catch(error => {
            submitMessage(data, false);
            console.error(error);
            setSendTime(Date.now());
        } );

        reset();
    } 

    const submitMessage = (data, success) => {
        if (success) messageApi.info(textForm[language]["form_success1"] + data.name + textForm[language]["form_success2"]);
        else messageApi.info(textForm[language]["form_failure"]);
    };

    // Input field component
    const InputFieldText = ({ labelText, placeholderText, fieldName, dark }) => (
        <div className={'form__field ' + dark}>
            <label>{labelText}</label>
            <Controller
                name={fieldName}
                control={control}
                render={({ field }) => (
                    <Input {...field} placeholder={placeholderText} />
                )}
                rules={{
                    required: textForm[language]['form_mandatory'],
                    maxLength: 40,
                    pattern: {
                    value: /^[a-zA-ZåÅäÄöÖüÜéÉ'\-\s]*$/,
                    message: textForm[language]['form_only_letters']
                    }
                }}
            />
            {errorMessage(errors[fieldName])}
        </div>
    );

    const onDark = (landing) ? '' : 'form__dark-color';

    return (
    <div className='offer__content-form'>
        <div className='offer__content-form_background' />
        {/* <div className='form__container form__dark-color'> */}
        <div className={'form__container ' + onDark}>
            <h1>{text[language]['contact-error-report']}</h1>
            <form onSubmit={(e) => {handleSubmit(onSubmit)(e);}}>
                <div className='form__container-row'>
                    <div className={'form__select ' + onDark}>
                        <label>{textForm[language]['form_subject']}</label>
                        <Controller
                            className="form__select_object"
                            name="errandOption"
                            control={control}
                            defaultValue={0}
                            render={({ field }) => (
                            <Select 
                                {...field}
                                options={availableErrands}
                                
                            />
                        )}/>   
                    </div>  
                    <InputFieldText 
                        labelText={textForm[language]['form_company']} 
                        placeholderText={textForm[language]['form_placeholder-company']}
                        fieldName={'company'} 
                        dark={onDark} 
                    />
                    <InputFieldText 
                        labelText={textForm[language]['form_name']} 
                        placeholderText={textForm[language]['form_placeholder-name']} 
                        fieldName={'name'} 
                        dark={onDark} 
                    />
                    <div className={'form__field ' + onDark}>
                        <label>{textForm[language]['form_phone']}</label>
                        <Controller
                            name="phone"
                            control={control}
                            render={({ field }) => (
                                <Input {...field} placeholder={textForm[language]['form_placeholder-phone']} />
                            )}
                            rules={{
                                required: textForm[language]['form_mandatory'],
                                maxLength: 20,
                                pattern: {
                                value: /^[0-9\+\-]*$/,
                                message: textForm[language]['form_only_digits']
                                }
                            }}
                        />
                        {errorMessage(errors.phone)}
                    </div>
                    <div className={'form__field ' + onDark}>
                    <label>{textForm[language]['form_mail']}</label>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                            <Input {...field} placeholder={textForm[language]['form_placeholder-mail']} />
                        )}
                        rules={{
                            required: textForm[language]['form_mandatory'],
                            maxLength: 50,
                            pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: textForm[language]['form_only_mail']
                            }
                        }}
                    />
                    {errorMessage(errors.email)}
                </div>
                </div>
                <div className='form__container-row'>
                    
                    <div className={'form__field ' + onDark}>
                        <label>{textForm[language]['form_text']}</label>
                        <Controller
                            name="message"
                            control={control}
                            render={({ field }) => (
                                <TextArea {...field} placeholder={textForm[language]['form_placeholder-info']} autoSize={{ minRows: 7.8, maxRows: 7.8 }} />
                            )}
                            rules={{
                                required: textForm[language]['form_mandatory'],
                                maxLength: 512,
                                pattern: {
                                value: /^[()0-9a-zA-ZåÅäÄöÖüÜéÉ\-',.!?\s]*$/,
                                message: textForm[language]['form_only_letters']
                                }
                            }}
                        />
                        {errorMessage(errors.message)}
                    </div>
                    {contextHolder}
                    <div className={'form__radio ' + onDark}>
                        <label>{textForm[language]['form_contact-type']}</label>
                        <Controller
                            control={control}
                            name="callType"
                            defaultValue="phone"
                            render={({ field: { onChange, value } }) => (
                            <Radio.Group onChange={onChange} defaultValue="phone" buttonStyle="solid">
                                <Radio.Button value="phone" >{textForm[language]['form_contact-phone']}</Radio.Button>
                                <Radio.Button value="mail" >{textForm[language]['form_contact-mail']}</Radio.Button>
                            </Radio.Group>
                            )}
                        />
                        
                    </div>
                    <button disabled={!accept} type='submit' className='button-6'> {textForm[language]['form_send']} </button>
                </div>
            </form>
            <SecureButton onAccept={onAccept} language={language} color={"black"} align={"row"} />
        </div>
    </div>
    );
};

export default OfferForm;