import { React } from 'react';
import api from '../api';
import { Form, Input, Tooltip } from "antd";
import CRUDadd from '../CRUDadd';



const AdminEmployeesAdd = ({ isModalOpen, closeModal, onLogout }) => {
    async function submitData(values) {
        const employee = JSON.stringify(values);
    
        api.post('EMPLOYEES/', employee)
          .then(response => {
            window.location.reload();
          })
          .catch(error => {
            console.error(error);
            onLogout();
          }); 
    }

    const items = (
        <>
        <Form.Item name="name" label="Namn" 
            rules={[{pattern: new RegExp(/^[ \-!?,.éÉüÜåäöÅÄÖa-zA-Z0-9]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item>
        <Tooltip placement="bottom" title={"Ett kolon ' : ' används för att separera huvudroll och underroll, t ex 'Projektledare: Stockholm'"}>
            <Form.Item name="role" label="Roll"
                rules={[{pattern: new RegExp(/^[ \-:!?,.éÉüÜåäöÅÄÖa-zA-Z0-9]*$/),message: "Inga skumma symboler tack!"},
                    {required: true,message: "Detta fält behövs!",},]}>
                <Input />
            </Form.Item>
        </Tooltip>
        <Form.Item name="mail" label="E-Mail"
            rules={[{pattern: new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),message: "exempel: namn@domän.se"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item>
        <Form.Item name="phone" label="Telefonnummer"
            rules={[{pattern: new RegExp(/^[\+\-0-9]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Lämna en nolla ifall nummer inte finns.",},]}>
            <Input />
        </Form.Item>
        <Tooltip placement="bottom" title={"Hur högt upp på sidan denna medarbetare skall visas (lägre nummer högre upp). T ex VD på rank 1, arbetsledare rank 2 osv."}>
            <Form.Item 
                name="rank" 
                label="Rank" 
                rules={[
                    { 
                        validator: (_, value) => {
                            if (!Number.isInteger(Number(value)) || Number(value) < 1) {
                                return Promise.reject(new Error('Rank måste vara ett heltal som är 1 eller högre!'));
                            }
                            return Promise.resolve();
                        }
                    },
                    { required: true, message: "Detta fält behövs!" },
                ]}
            >
            <Input type="number" />
        </Form.Item>
        </Tooltip>
        </>
    )

    return (<CRUDadd isModalOpen={isModalOpen} closeModal={closeModal} handleSubmitData={submitData} title={"Lägg till ny medarbetare"} items={items} />);
};

export default AdminEmployeesAdd;