/**
 * All selectable alternative in offer arrands
 */

const offerFormAll = {
    ["sv"]: [
        { value: 0, label: "Avtal för driftunderhåll" },
        { value: 1, label: "Armaturbyte" },
        { value: 2, label: "Besiktning" },
        { value: 3, label: "Drift- och underhållsplan" },
        { value: 4, label: "Energikartläggning" },
        { value: 5, label: "Information ljusprodukter" },
        { value: 6, label: "Ljusberäkning" },
        { value: 7, label: "Mätning" },
        { value: 8, label: "Offert för belysningsanläggning" },
        { value: 9, label: "Projektering" },
        { value: 10, label: "Övriga ärenden" },
    ],
    ["en"]: [
        { value: 0, label: "Operational Maintenance Agreements" },
        { value: 1, label: "Armature Change" },
        { value: 2, label: "Design" },
        { value: 3, label: "Energy Audit" },
        { value: 4, label: "Inspection" },
        { value: 5, label: "Light Products Information" },
        { value: 6, label: "Light calculation" },
        { value: 7, label: "Measurement" },
        { value: 8, label: "Operation and maintenance plan" },
        { value: 9, label: "Quote for lighting system" },
        { value: 10, label: "Other arrends" },
    ]
};

export { offerFormAll };