import React, { useState, useContext, useMemo, useEffect } from 'react';
import { HolderOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Modal, Select, Table } from "antd";
import { CSS } from '@dnd-kit/utilities';
import api from '../api';

// For dragging rows in the table
const RowContext = React.createContext({});
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: 'move',
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const columns = [
    {
      key: 'sort',
      align: 'center',
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Roll',
      dataIndex: 'role',
    },
    {
      title: 'Rank',
      dataIndex: 'rank',
    },
];


// Code for draggable rows
const Row = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    });
    const style = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999,
            }
        : {}),
    };
    const contextValue = useMemo(
        () => ({
        setActivatorNodeRef,
        listeners,
        }),
        [setActivatorNodeRef, listeners],
    );
    return (
        <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    );
};

const AdminEmployeesOrder = ({ closeModal, isOpen, initialData }) => {
    const [dataSource, setDataSource] = useState([]);
    const [chosenRank, setChosenRank] = useState(0);

    // When chosing a rank, get all employees of rank
    useEffect(() => {
        let usersOfRank = initialData.filter(e => e.rank === chosenRank);
        // Add keys to each row
        usersOfRank.forEach(e => {
            e.key = e.position;
        });
        // Sort by position
        usersOfRank = usersOfRank.sort((a, b) => {return a.position - b.position;} )
        setDataSource(usersOfRank);
    }, [chosenRank]);

    // Send data
    const handleOk = () => {
        if (dataSource.length === 0) return;
        // Take the new index positions of each row and put in a list bundled with user id
        let sendData = [];
        for (let i = 0; i < dataSource.length; i++) {
            sendData.push({ id: dataSource[i].id, position: i+1});
        }
        const sendPackage = JSON.stringify(sendData);

        // Send to server
        api.post('EMPLOYEES_ORDER/', sendPackage)
        .then(response => {
            window.location.reload();
            closeModal();
        })
        .catch(error => {
            console.error(error);
        });
    };

    const handleCancel = () => {
        closeModal();
    };
    
    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
        setDataSource((prevState) => {
            const activeIndex = prevState.findIndex((record) => record.key === active?.id);
            const overIndex = prevState.findIndex((record) => record.key === over?.id);
            return arrayMove(prevState, activeIndex, overIndex);
            });
        }
    };

    // Get a list of all ranks
    const ranks = initialData.map(e => e.rank);
    const ranksList = [...new Set(ranks)];
    const ranksOptions = ranksList.map(e => ({ value: e, label: e }));
    const setRank = (value) => { setChosenRank(value); };

    return (
        <Modal 
            title="Ändra ordning"
            open={isOpen}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                  Avbryt
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                  Slutför
                </Button>,
              ]}
        >

        <div className='admin__rank'>
            <span> Välj rank: </span>
            <Select
            defaultValue={0}
            style={{
                width: 120,
            }}
            onChange={setRank}
            options={ranksOptions}
            />
        </div>
        <br />

        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext items={dataSource.map((i) => i.key)} strategy={verticalListSortingStrategy}>
            <Table
            rowKey="key"
            components={{
                body: {
                row: Row,
                },
            }}
            columns={columns}
            dataSource={dataSource}
            />
        </SortableContext>
        </DndContext>

        </Modal>
    );
};

export default AdminEmployeesOrder;