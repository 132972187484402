import { React, useState} from 'react';
import { Button, Modal, Form, Input } from "antd";
import api from '../api';

const BETWEEN_LOGIN_TIME = 2000; // 2 seconds between each try

const ChangePassword = ({ closeModal, isOpen, username }) => {
    const [form] = Form.useForm();
    const [loginTime, setLoginTime] = useState(Date.now());

    async function submitNewPassword() {
        const { oldPassword, newPassword, newPassword2 } = form.getFieldsValue();
        const sendPackage = JSON.stringify({ oldPassword, newPassword, username });
    
        console.log(sendPackage);

        api.post('CHANGE_PASSWORD/', sendPackage)
        .then(response => {
            window.location.reload();
            form.resetFields();
            closeModal();
        })
        .catch(error => {
            form.setFieldValue('oldPassword', '');
            console.error(error);
            // onLogout();
        });
    }

    const handleOk = () => {
        // Force the user to wait 2 seconds between each send
        if ((Date.now() - loginTime) < BETWEEN_LOGIN_TIME) return;
        setLoginTime(Date.now());
        // Check for errors
        const errors = form.getFieldsError();
        let canSubmit = true;
        errors.forEach((e) => {
            if (e.errors.length > 0) canSubmit = false;
            if (form.getFieldValue(e.name[0]) == undefined) canSubmit = false;
        })

        // If we can submit then submit
        if (canSubmit) {
            submitNewPassword();
        }
    };

    const handleCancel = () => {
        form.resetFields();
        closeModal();
    };

    const passwordPattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);

    return (
        <Modal 
            title="Byt lösenord"
            open={isOpen}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                  Avbryt
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                  Slutför
                </Button>,
              ]}
        >

        <Form
            form={form}
            labelCol={{
            span: 10,
            }}
            wrapperCol={{
            span: 16,
            }}
            layout="horizontal"
            style={{
            maxWidth: 800,
            }}
        >

            <Form.Item
                label="Nuvarande lösenord"
                name="oldPassword"
                rules={[
                    {pattern: passwordPattern, message: "Minst 8 tecken, stor/liten bokstav, siffra samt tecken!"},
                    {
                    required: true,
                    message: 'Fyll i ditt nuvarande lösenord',
                    },
                ]}
                >
                <Input.Password />
            </Form.Item>
            <Form.Item
                label="Nytt lösenord"
                name="newPassword"
                rules={[
                    {pattern: passwordPattern, message: "Minst 8 tecken, stor/liten bokstav, siffra samt tecken!"},
                    {
                    required: true,
                    message: 'Fyll i ditt nya lösenord',
                    },
                ]}
                >
                <Input.Password />
            </Form.Item>
            <Form.Item
                label="Nytt lösenord (igen)"
                name="newPassword2"
                rules={[
                    {pattern: passwordPattern, message: "Minst 8 tecken, stor/liten bokstav, siffra samt tecken!"},
                    {
                    required: true,
                    message: 'Fyll i ditt nya lösenord',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('De nya lösenordet matchar inte på båda fälten!'));
                        },
                      }),
                ]}
                >
                <Input.Password />
            </Form.Item>

        </Form>
        </Modal>
    );
};

export default ChangePassword;