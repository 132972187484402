import React, {useEffect, useState}  from 'react';
import { useForm, Controller } from "react-hook-form"
import SecureButton from '../../form/SecureButton';
import { Select, Input, message } from "antd";
// Styling
import '../../form/form.css';

let text = require('./text.json');
let textForm = require('../../form/text.json');
const { TextArea } = Input;

/**
 * @brief Adds the title of all jobs into the job category list
 * @param {JSON} data All job data 
 * @returns A list of type {value: '..', label: '..'}
 */
function addAvailableTitles(data, language) {
    const availableTitles = [{ value: 'default', label: (language === 'sv') ? 'Spontanansökan' : 'Open application' }];
    for (let i = 0; i < data.length; i ++) {
        // TODO: Lägga till jobb för olika språk
        if (language === 'sv') availableTitles.push({ value: data[i].title, label: data[i].title });
    }
    return availableTitles;
}

// Displays error message if form field input is wrong
const errorMessage = (error) => (
    <p className='form__error-message'>{error && ('(' + error.message + ')')} </p>
);

const BETWEEN_SEND_TIME = 2000; // 2 seconds between each try

// A work appliance form component
const WorkForm = ({ language, defaultJob, availableWork }) => {
    
    const { reset, handleSubmit, register, control, setValue, formState: { errors } } = useForm();
    const [sendTime, setSendTime] = useState(Date.now());
    // Get all available job titles
    const availableTitles = addAvailableTitles(availableWork, language);
    
    // For accepting the terms
    const [accept, setAccept] = useState(false);
    const onAccept = (value) => { setAccept(value); }

    // Submit button action - sends an email through php
    async function onSubmit(data) {
        if (!accept) return;
        if ((Date.now() - sendTime) < BETWEEN_SEND_TIME) return; // Mimimum time to wait between each send to avoid spamming

        const url = process.env.REACT_APP_PHP_WORKFORM;
        // Find job title
        const job = data['jobOption'];
        const found = availableTitles.findIndex((e) => e.value === job);
        // Create a new FormData object to send the data
        const formData = new FormData();
        formData.append('job', availableTitles[found].label);
        formData.append('name', data['name']);
        formData.append('phone', data['phone']);
        formData.append('email', data['email']);
        formData.append('personal_letter', data['personal_letter']);
        formData.append('file_cv', data['file_cv'][0]);

        // Send the request using the Fetch API
        await fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
        .then(response => {
            if (response.status === 200) submitMessage(data, 'success');
            if (response.status === 412) submitMessage(data, 'no-files');
            if (response.status === 413) submitMessage(data, 'too-big');
            if (response.status === 408) submitMessage(data, 'mail-failed');
            setSendTime(Date.now());
        })
        .catch(error => {
            submitMessage(data, 'error');
            console.error(error);
            setSendTime(Date.now());
        });

        reset();
    } 

    // Set the default job title (if we apply for a job we change this to the applied job title)
    useEffect(() => {
        setValue('jobOption', defaultJob);
      }, [defaultJob, setValue]);

    // Used for pop-up message when applying for job
    const [messageApi, contextHolder] = message.useMessage();
    const submitMessage = (data, success) => {
        if (success === 'success') messageApi.info(
            text[language]["jobs_form_message-success1"] + data.name + text[language]["jobs_form_message-success2"]);
        if (success === 'no-files') messageApi.info(text[language]["jobs_form_message-no-files"]);
        if (success === 'too-big') messageApi.info(text[language]["jobs_form_message-too-big"]);
        if (success === 'mail-failed') messageApi.info(text[language]["jobs_form_message-connection"]);
        if (success === 'error') messageApi.info(text[language]["jobs_form_message-error"]);
    };

    return (
        <div className='work__landing-form'>
            <div className='form__container'>
                <h3 style={{"color": "white"}} className='h3'>{text[language]['jobs_apply']}</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form__container-row'>
                        <div className='form__select'>
                            <Controller
                                className="work__landing-form-selectjob"
                                name="jobOption"
                                control={control}
                                defaultValue={defaultJob}
                                render={({ field }) => (
                                <Select 
                                    {...field}
                                    options={availableTitles}
                                    
                                />
                            )}/>   
                            </div>              
                        <div className='form__field'>
                            <label>{textForm[language]['form_name']}</label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <Input {...field} placeholder={textForm[language]['form_placeholder-name']} />
                                )}
                                rules={{
                                    required: textForm[language]['form_mandatory'],
                                    maxLength: 40,
                                    pattern: {
                                    value: /^[a-zA-ZåÅäÄöÖüÜéÉ\-\s]*$/,
                                    message: textForm[language]['form_only_letters']
                                    }
                                }}
                            />
                            {errorMessage(errors.name)}
                        </div>
                        <div className='form__field'>
                            <label>{textForm[language]['form_phone']}</label>
                            <Controller
                                name="phone"
                                control={control}
                                render={({ field }) => (
                                    <Input {...field} placeholder={textForm[language]['form_placeholder-phone']} />
                                )}
                                rules={{
                                    required: textForm[language]['form_mandatory'],
                                    maxLength: 20,
                                    pattern: {
                                    value: /^[0-9\+\-]*$/,
                                    message: textForm[language]['form_only_digits']
                                    }
                                }}
                            />
                            {errorMessage(errors.phone)}
                        </div>
                        <div className='form__field'>
                            <label>{textForm[language]['form_mail']}</label>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <Input {...field} placeholder={textForm[language]['form_placeholder-mail']} />
                                )}
                                rules={{
                                    required: textForm[language]['form_mandatory'],
                                    maxLength: 50,
                                    pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: textForm[language]['form_only_mail']
                                    }
                                }}
                            />
                            {errorMessage(errors.email)}
                        </div>
                        <div className='form__field-file'>
                            <label>{textForm[language]['form_cv']}</label>
                            <input 
                                type="file" 
                                {...register('file_cv')}
                                accept=".txt, .pdf, .doc, .docx" 
                            />
                             <span>(max 100 KB)</span>
                        </div>
                        <div className={'form__field form__field-text'}>
                        <label>{textForm[language]['form_personal_letter']}</label>
                        <Controller
                            name="personal_letter"
                            control={control}
                            render={({ field }) => (
                                <TextArea {...field} placeholder={textForm[language]['form_placeholder-info']} autoSize={{ minRows: 3, maxRows: 3 }} />
                            )}
                            rules={{
                                required: textForm[language]['form_mandatory'],
                                minLength: {
                                    value: 50,
                                    message: text[language]["jobs_form-min"] 
                                },
                                maxLength: {
                                    value: 1500,
                                    message: text[language]["jobs_form-max"] 
                                },
                                pattern: {
                                value: /^[()0-9a-zA-ZåÅäÄöÖüÜéÉ\-',.!?\n\s\']*$/,
                                message: textForm[language]['form_only_letters']
                                }
                            }}
                        />
                        {errorMessage(errors.personal_letter)}
                    </div>
                        {contextHolder}
                        <button disabled={!accept} className='button-6'>{textForm[language]['form_send']}</button>
                    </div>
                </form>
                <div className='work__form-accept'>
                    <SecureButton onAccept={onAccept} language={language} color={"white"} align={"column"} />
                </div>
            </div>
        </div>
    );
}

export default WorkForm;