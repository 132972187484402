import React from 'react';
import Landing from '../../landing/Landing';
import scrollIntoView from '../../landing/scrollIntoView';
import ShowMore from '../../landing/ShowMore';
import { NavLink } from 'react-router-dom';
// React components
import References from './AboutReferences';
import InformationTab from '../../companyInformation/CompanyInformation';
// Styling
import './about.css';
import '../../companyInformation/companyInformation.css'
// Assets
import AboutCompany from '../../../assets/about-overview.jpg'; 
import AboutOrg from '../../../assets/about-organisation.JPG'; 
import AboutVehicle1 from '../../../assets/about-vehicle1.jpg'; 
import AboutVehicle2 from '../../../assets/about-vehicle2.jpg'; 
import AboutVehicle3 from '../../../assets/about-vehicle3.jpg'; 
import AboutVehicle4 from '../../../assets/about-vehicle4.jpg'; 
import AboutCSR from '../../../assets/bogfelts-diplom-krympt.jpg'; 
import AboutClearence from '../../../assets/bogfelts-grop.jpg'; 

import LandingBackground from '../../../assets/car.jpg'; 

let text = require('./text.json');


const About = ({ language }) => {
    const LandingContent = () => (
        <>
        <div className='about__landing'>
            <div className='about__landing-slogan'>
                <p className='p1'>{text[language]["about-landing_slogan-text"].toUpperCase()}</p>
                <h1>{text[language]["about-landing_slogan-title"].toUpperCase()}</h1>
            </div>
            <p className='p1'>{text[language]["about-landing_text"]}</p>
            <div className='about__landing-buttons'>
                <NavLink className='button button_hollow-white' onClick={() => scrollIntoView("about__info")}>
                    {text[language]["about-landing_button-more"].toUpperCase()}
                </NavLink>
                <NavLink className='button button_filled-black' onClick={() => scrollIntoView("about__video")}>
                    {text[language]["about-landing_button-pres"].toUpperCase()}
                </NavLink>
            </div>
        </div>
        <ShowMore scrollTo={"body__content-id"} text={text[language]['about-more']} />
        </>
    );

    return (
        <>
            <Landing language={language} content={LandingContent()} image={LandingBackground} />
            <div className='body__content'>

                <div className='body__content-empty-space'></div>

                <h1 id='about__video'>{text[language]["about-video-title"]}</h1>
                <br />
                <div className='about__video'>
                    <iframe 
                        width="100%" 
                        height="100%" 
                        src="https://www.youtube.com/embed/JJiB2DRNIck?si=dLGvRwh8QfnhQgM2" 
                        title="Bogfelts Street Lights AB Presentation" 
                        allow="accelerometer; 
                        clipboard-write; 
                        encrypted-media; 
                        gyroscope; 
                        picture-in-picture; 
                        web-share" 
                        referrerPolicy="strict-origin-when-cross-origin" 
                        allowFullScreen
                    >
                    </iframe>
                </div>

                <div className='body__content-empty-space' id='about__info'></div>
                <br />
           
                <InformationTab image={AboutCompany} id={"about__company"} alt={"about company"} title={text[language]["about-title"]} direction={"left"}
                    text={[text[language]["about-overview-text1"], text[language]["about-overview-text2"], text[language]["about-overview-text3"], text[language]["about-overview-text4"]]} />

                <div className='body__content-empty-space'></div>

                <InformationTab image={AboutOrg} id={"about__org"} alt={"about organisation"} title={text[language]["about-org-title"]} direction={"right"}
                    text={[text[language]["about-org-fulltext1"], text[language]["about-org-fulltext2"], text[language]["about-org-fulltext3"]]} />
                
                <div className='body__content-empty-space'></div>

                <InformationTab image={[AboutVehicle1, AboutVehicle2, AboutVehicle3, AboutVehicle4]} id={"about__vehicle"} alt={"about vehicles"} title={text[language]["about-vehicles-title"]} direction={"left"}
                    text={[text[language]["about-vehicles-fulltext1"], text[language]["about-vehicles-fulltext2"], text[language]["about-vehicles-fulltext3"]]} />

                <div className='body__content-empty-space'></div>

                <InformationTab image={AboutCSR} id={"about__csr"} alt={"about csr"} title={text[language]["about-csr-title"]} direction={"right"}
                    text={[text[language]["about-csr-fulltext1"], text[language]["about-csr-fulltext2"], text[language]["about-csr-fulltext3"]]} />
                
                <div className='body__content-empty-space'></div>

                <InformationTab image={AboutClearence} id={"about__clearence"} alt={"about clearence"} title={text[language]["about-clearence-title"]} direction={"left"}
                    text={[text[language]["about-clearence-fulltext1"], text[language]["about-clearence-fulltext2"]]} />

                <div className='body__content-empty-space'></div>

                <References title={text[language]["about-refs-title"]} text={''} id={"about__ref"} />

                <div className='body__content-empty-space'></div>

            </div>
            </>
    )
}

export default About;