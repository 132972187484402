import { React, useState } from 'react';
import api from '../api';
import { Form, Input, Switch, Upload, message, Tooltip } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import CRUDadd from '../CRUDadd';

// Norm number of files
const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
};

const AdminCustomersAdd = ({ isModalOpen, closeModal, onLogout }) => {
    const [fileList, setFileList] = useState([]);
    const [isValid, setIsValid] = useState(true);

    // Ignore early upload
    const handleUpload = () => { }
    
    // Input validation of image
    const uploadImageValidation = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Fil måste vara av typ JPG/PNG/GIF!');
        }
        const isLt2M = file.size / 1024 / 1024 < 0.2;
        if (!isLt2M) {
            message.error('Bilden får max vara 200KB!');
        }
        setIsValid(isJpgOrPng && isLt2M);
        setFileList([...fileList, file]);
        return false;
    };

    async function submitData(values) {

        const formData = new FormData();
        // Check whether there is a valid file
        if (fileList.length === 0 || !isValid) return;
        // Make sure (again) the image is of the right file format
        if (values['file'][0].type === "image/png" || values['file'][0].type === "image/jpeg" ) {

            // Append the field values
            for (const key in values) {
                if (values.hasOwnProperty(key) && key !== 'file') {
                    formData.append(key, values[key]);
                }
            }
            formData.append('file', fileList[0]);

        api.post('/CUSTOMERS', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            console.log(response.data);
            window.location.reload();
        })
        .catch(error => {
            console.error(error);
            onLogout();
        });
        } else {
            console.log("Incorrect type!");
        }
    }

    const items = (
        <>
        <Form.Item name="name" label="Namn" 
            rules={[{pattern: new RegExp(/^[ \-!?,.éÉüÜåäöÅÄÖa-zA-Z0-9]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item>
        <Tooltip placement="bottom" title={"Län / Landskap eller t ex 'Entreprenader'"}>
            <Form.Item name="area" label="Område"
                rules={[{pattern: new RegExp(/^[ \-!?,.éÉüÜåäöÅÄÖa-zA-Z0-9]*$/),message: "Inga skumma symboler tack!"},
                    {required: true,message: "Detta fält behövs!",},]}>
                <Input />
            </Form.Item>
        </Tooltip>
        <Form.Item name="href" label="Länk-adress"
            rules={[{pattern: new RegExp(/^[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=]+$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item>
        <Tooltip placement="bottom" title={"Huruvida kunden skall synnas under 'referenser' på 'Om Oss'-sidan."}>
            <Form.Item label="Visa i ref." name="visible" initialValue={false}>
            <Switch />
            </Form.Item>
        </Tooltip>
        <Form.Item name="file" label="Bild" valuePropName="fileList" getValueFromEvent={normFile}>
            <Upload 
                maxCount={1} 
                beforeUpload={uploadImageValidation} 
                listType="picture-card">
                <button
                style={{
                    border: 0,
                    background: 'none',
                }}
                type="button"
                onClick={handleUpload}
                >
                <PlusOutlined />
                <div
                    style={{
                    marginTop: 8,
                    }}
                >
                    Upload
                </div>
                </button>
            </Upload>
        </Form.Item>
        </>
    )

    return (<CRUDadd isModalOpen={isModalOpen} closeModal={closeModal} handleSubmitData={submitData} title={"Lägg till ny kund"} items={items} />);
};

export default AdminCustomersAdd;