import { React, useState} from 'react';
import { Button, Modal, Form } from "antd";

/**
 *  CRUDadd is used as a component for all Add and Put-operations. 
 *  
 *  Used by AdminCustomersAdd, AdminCustomersPut etc...
 */

const CRUDadd = ({ isModalOpen, closeModal, handleSubmitData, title, items }) => {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const handleOk = () => {
        // Check for errors
        const errors = form.getFieldsError();
        let canSubmit = true;
        errors.forEach((e) => {
            if (e.errors.length > 0) canSubmit = false;
            if (form.getFieldValue(e.name[0]) == undefined) canSubmit = false;
        })

        // If we can submit then submit
        if (canSubmit) {
            setLoading(true);
            setTimeout(() => {
                handleSubmitData(form.getFieldsValue());

                setLoading(false);
                form.resetFields();
                closeModal();
            }, 1000);
        }
    };
    const handleCancel = () => {
        form.resetFields();
        closeModal();
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e?.fileList;
    };
    
    return (
        <Modal 
            title={title}
            open={isModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                  Avbryt
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                  Slutför
                </Button>,
              ]}
        >

        <Form
            form={form}
            labelCol={{
            span: 7,
            }}
            wrapperCol={{
            span: 16,
            }}
            layout="horizontal"
            style={{
            maxWidth: 800,
            }}
        >

        {items /* The actual items to put in this modal*/ }

        </Form>
        </Modal>
    )
};

export default CRUDadd;