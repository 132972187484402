import React, { useState, useEffect } from 'react';
import axios from "axios";
import './aboutReferences.css';

/**
 * @brief Get all unique area names
 * @param {*} customers 
 * @returns A dictionary of areas and how many customers each have
 */
function getUniqueAreas(customers) {
    const areas = {};
    customers.forEach((e) => {
        if (!areas[e.area]) {
            areas[e.area] = 1;
        } else {
            areas[e.area]++;
        }
    });

    return areas;
}

/**
 * @brief Get the HTML for all logos within an area
 * @param {array[json]} customers 
 * @param {string} area 
 * @returns HTML for each area
 */
function createLogosForArea(customers, area) {
    // Filter customers by area before mapping
    const filteredCustomers = customers.filter(customer => customer.area === area);

    return (
        <React.Fragment key={"Logos_area_" + area}>
            <h2>{area}</h2>
            <div className='logos__content-logos-area'> 
                {filteredCustomers.map((customer, index) => {
                    const key = `logo_${customer.name}_${index}`;
                    return (
                        <div key={key} className='logos__content-logos-container'>
                            <a href={customer.href}>
                                <img src={customer.image} alt={customer.name} loading='lazy' />
                            </a>
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
}

/**
 * 
 * @param {array[json]} logos 
 * @returns HTML of a container with all logos
 */
function createLogos(logos) {
    const areas = getUniqueAreas(logos);

    // Sort all areas based on the number of customers
    const areaNames = Object.keys(areas);
    const areasSorted = areaNames.sort((a, b) => {
        return areas[b] - areas[a];
    });

    // Create all HTML
    const logosHTML = [];
    areasSorted.forEach((e) => {
        logosHTML.push(createLogosForArea(logos, e));
    })

    return logosHTML;
} 

// The Component for references
const AboutReferences = ({ title, text, id }) => {
    const [customers, setCustomers] = useState([]);

    function fetchCustomersData() {

        let fetchedCustomers = [];
        
        const backendUrl = process.env.REACT_APP_BACKEND_PHP;
        // First get the customer text data
        axios.get(backendUrl + 'CUSTOMERS/')
        .then(async response => {
            // Check whether empty data has been found in database
            if (response.data == undefined || response.data == null)
                throw "Data could not be found!";

            fetchedCustomers = response.data;
            // Then get all images
            const promises = [];
            for (let i = 0; i < fetchedCustomers.length; i++) {
                // Skip customers that are marked as non-visible
                if (!fetchedCustomers[i].visible) {
                    continue;
                }

                fetchedCustomers[i].image = "";
                promises.push(new Promise((resolve, reject) => {
                    fetch(backendUrl + 'CUSTOMERS/IMAGES/?id=' + fetchedCustomers[i].id)
                    .then(response => response.blob())
                    .then(blob => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            fetchedCustomers[i].image = reader.result;
                            resolve(); // Resolve the promise when the image is read
                        };
                        reader.readAsDataURL(blob);
                    })
                    .catch(error => reject(error));
                }));
            }
            // Wait for all images to arrive before storing customers
            Promise.all(promises) 
            .then(() => {
                const visibleCustomers = fetchedCustomers.filter((customer) => customer.visible);
                setCustomers(visibleCustomers);
            })
            .catch(error => console.error(error));
        })
        .catch(error => {
            console.log(error);
        });
    }

    // Fetch news from server
    useEffect(() => {
        if (customers.length === 0) fetchCustomersData();
    }, [customers.length]);

    return (
    <div className='logos__content' id={id}>
        <h1 className='h2' id={id + '_title'}>{title}</h1>
        <hr />
        <p id={id + '_text'}>{text}</p>
        <div id={id + '_logos'} className='logos__content-logos'>
            {
                createLogos(customers)
            }    
        </div>
    </div>
    );
}

export default AboutReferences;

