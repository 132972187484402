import React from 'react';
import { NavLink } from 'react-router-dom';
import scrollIntoView from './scrollIntoView';
import { BiSolidChevronsDown } from "react-icons/bi";
import './landing.css';

const ShowMore = ( {scrollTo, text} ) => (
    <div className='landing__show-more'>
        <NavLink onClick={() => scrollIntoView(scrollTo)}>
            <BiSolidChevronsDown className='heartbeat'  color='white' size={36}/>
            <p>{text}</p>
            <BiSolidChevronsDown className='heartbeat'  color='white' size={36}/>
        </NavLink>
    </div>
);

export default ShowMore;


