import React from 'react';
import { createRoot } from 'react-dom/client'; 
import { HashRouter as Router } from 'react-router-dom';
import { getCookie } from './functionality/cookies';

import TagManager from 'react-gtm-module'

import App from './App.js';
import './index.css';

if (getCookie('cookie-accept') !== '') {
    const tagManagerArgs = {
        gtmId: 'GTM-WMJ975NR'
    }
    
    TagManager.initialize(tagManagerArgs)

    console.log("User accepts cookies, google analytics starting...");
}


// Replaced "ReactDom.render(..)"
const root = createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>
);
