import React, {useRef, useState, useEffect, Suspense} from 'react';
import './companyInformation.css';

const CompanyInformationSwipe = React.lazy(() => import('./CompanyInformationSwipe'));

/**
 * @brief Company Information Tab - used in "Offers" and "About us" to describe the company with text and a picture
 * @param {image} image - could be one image or a list of images
 * @param {string} alt
 * @param {string} id
 * @param {string} direction 'left' or 'right' - how to align the image in relation to the text
 * @param {title} title
 * @param {[string]} text list of text strings 
 * @returns A React component
 */
const CompanyInformation = ({ image, alt, id, direction, title, text }) => {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    // The text could be made of multiple paragraphs to split it into multiple lines
    const makeStrings = () => {
        const paragraphs = [];
        for (let i = 0; i < text.length; i++) {
            paragraphs.push(<p key={alt + "_" + id + "_" + i}>{text[i]}</p>);
        }

        return (<>{paragraphs}</>);
    }

    // Use a "loading" view if the swiper thingy hasn't loaded yet
    const lazyLoadSlideShow = () => (
        <Suspense fallback={<h1>Laddar...</h1>}>
            <CompanyInformationSwipe images={image} alt={alt} />
        </Suspense>
    );

    // Either use one image or a slide show of images
    const images = (image instanceof Array)
        ? lazyLoadSlideShow()
        : <img src={image} alt={alt} />;
    
    // Makes this component pop up when it is partly visible
    useEffect(() => {
        const current = elementRef.current; // Current object

        const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
            setIsVisible(true);
                observer.unobserve(entry.target); // Stop observing once the element is visible
            }
        },
        {
            threshold: (window.screen.width < 850) ? 0.2 : 0.5, // Adjust this value according to when you want the animation to trigger
        }
        );
    
        if (current) {
        observer.observe(current);
        }
    
        return () => {
        if (current) {
            observer.unobserve(current);
        }
        };
    }, []);

    return (
        <div ref={elementRef} id={id} className={`company-information ${isVisible ? ((direction === 'left') ? 'swing-in-left-fwd' : 'swing-in-right-fwd company__right') : 'hidden'}`}>
            <div className='company-information-image'>
                {images}
            </div>
            <div className={`company-information-text ${(direction === 'left') ? '' : 'company__right'}`}>
                <h1>{title}</h1>
                <hr />
                {makeStrings()}
            </div>
        </div>
    );
}

export default CompanyInformation;