import React, {useState, useEffect} from 'react';
import axios from "axios";
// Styling
import './contactPersonel.css';
import { BsEnvelope, BsTelephone } from "react-icons/bs";

/**
 * This component represents a container for all personel in the contact page
 */

/**
 * @brief Create a space for people of a rank
 * @param {[JSON]} personel 
 * @returns HTML
 */
function createPersonelRank(personel) {
    if (personel == undefined || typeof(personel) === 'string') return [];

    const sortedByPosition = personel.sort((a, b) => a.position - b.position);

    return sortedByPosition.map((person, index) => {
        const numberMissing = person.phone === '0' || person.phone === ''; 
        const roles = person.role.split(':');
        const [primeRole, secondaryRole] = roles;

        return (
            <div key={`${person.rank}-${person.name}-${index}`} className='contact__people'>
                <h1>{person.name}</h1>
                <div className='contact__people-roles'>
                    <h2 className='contact__people-prime-role'>{primeRole}</h2>
                    <h2 className='contact__people-second-role'>{secondaryRole}</h2>
                </div>
                {!numberMissing && (
                    <div className='contact__people-icon-field'>
                        <BsTelephone color='white' size="24" />
                        <p>{person.phone}</p>
                    </div>
                )}
                <div className='contact__people-icon-field'>
                    <BsEnvelope color='white' size="24" />
                    <p>{person.mail}</p>
                </div>
            </div>
        );
    });
}

/**
 * @brief Creates HTML for each employee
 * @param {[JSON]} personel 
 * @returns A React Fragments
 */
function createPersonel(personel) {
    // Get a list of all ranks
    const allRanks = Array.from(new Set(personel.map(person => person.rank)));
    
    return allRanks.map((rank, index) => {
        const peopleOfRank = personel.filter(e => e.rank === rank);
        return (
            <React.Fragment key={`rank-${rank}-${index}`}>
                <br key={`space-before-${rank}`} />
                <div className='contact__content-people'>
                    {createPersonelRank(peopleOfRank)}
                </div>
                <br key={`space-after-${rank}`} />
            </React.Fragment>
        );
    });
}

const ContactPersonel = ( {language} ) => {
    const [personel, setPersonel] = useState([]);

    // Get personel from the server
    useEffect(() => {
        const url = process.env.REACT_APP_BACKEND_PHP;
        axios.get(url + 'EMPLOYEES/') 
          .then(response => {
            try {
                const processData = response.data;
                if (processData != undefined && processData != null)
                    setPersonel(processData);
            } catch(e) {
                console.log("An error has occured: " + e);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }, []);

    return (
        <>
        { createPersonel(personel) }
        </>
    )
}



export default ContactPersonel;