import { React, useState, useEffect } from 'react';
import { Button, Select, Modal } from "antd";
import api from '../api';
// React components
import AdminEmployeesAdd from './AdminEmployeesAdd';
import AdminEmployeesPut from './AdminEmployeesPut';
import AdminEmployeesOrder from './AdminEmployeesOrder';
// Assets
import { ExclamationCircleFilled } from '@ant-design/icons';

/**
 * @brief Iterate JSON object with titles and generate data for news selection menu
 * @param {JSON} data 
 * @returns a list of json 
 */
function selectOptions(data) {
    const options = [];
    for (let i = 0; i < data.length; i++) {
        const newsTitle = {
            value: i,
            label: data[i].name
        };
        options.push(newsTitle);
    }

    return options;
}

const AdminEmployees = ({ onLogout }) => {
    // Handle news
    const [key, setKey] = useState(0); // To force re-draw modify screen
    const [employees, setEmployees] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [selected, setSelected] = useState(0);
    // Choose selected title
    const handleChange = (value) => { 
        setSelected(value); 
        setKey(prevkey => prevkey + 1); 
    };
    // Used for confirming delete operation
    const { confirm } = Modal;
    // Either get a selected entry of a default empty entry
    function getCurrentEmployee() {
        if (employeeData.length === 0) return {id: 0, name: "", phone: "", mail: "", rank: 0, position: 0};
        else return employeeData[selected];
    }

    // Fetch all news titles
    useEffect(() => {
        const backendUrl = process.env.REACT_APP_BACKEND_PHP;

        // Get news titles and set them to selection menu
        api.get(backendUrl + 'EMPLOYEES/') 
            .then(response => {
                const options = selectOptions(response.data);
                setEmployees(options);
                setEmployeeData(response.data)
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    // Handle CRUD
    const [addOpen, setAddOpen] = useState(false);
    const [putOpen, setPutOpen] = useState(false);
    const [orderOpen, setOrderOpen] = useState(false);

    const showAdd = () => { setAddOpen(true); };
    const closeAdd = () => { setAddOpen(false); };

    const showPut = () => { setPutOpen(true); };
    const closePut = () => { setPutOpen(false); };

    const showOrder = () => { setOrderOpen(true); };
    const closeOrder = () => { setOrderOpen(false); };


    /**
     * @brief Used for deleting an employee
     * @returns 
     */
    const showConfirmDeletion = () => {
        if (employeeData.length === 0) return;

        confirm({
            title: `Vill du verkligen ta bort ${employeeData[selected].name}?`,
            icon: <ExclamationCircleFilled />,
            content: 'Obs! Åtgärden går inte att ångra!',
            cancelText: 'Avbryt',
            okText: 'Ja',
            okType: 'danger',
            onOk() {
                const backendUrl = process.env.REACT_APP_BACKEND_PHP;

                // Get news titles and set them to selection menu
                api.delete(backendUrl + 'EMPLOYEES/' + employeeData[selected].id) 
                    .then(response => {
                        window.location.reload();
                    })
                    .catch(error => {
                        console.log(error);
                        onLogout();
                    });
            },
            onCancel() {
                
            },
        });
    };
    

    return (
        <>
        <h1>Medarbetare</h1>
        <Select
            defaultValue={0}
            style={{
                width: "50%",
                textAlign: "center",
            }}
            onChange={handleChange}
            options={employees}
        />
        <Button className='admin__main-button' onClick={showAdd}>Lägg till ny</Button>
        <AdminEmployeesAdd isModalOpen={addOpen} closeModal={closeAdd} onLogout={onLogout} />
        <Button className='admin__main-button' onClick={showPut}>Redigera</Button>
        <AdminEmployeesPut key={"employees_put_form" + key} isModalOpen={putOpen} closeModal={closePut} onGetCurrentEmployee={getCurrentEmployee} onLogout={onLogout} />
        <Button className='admin__main-button' onClick={showConfirmDeletion}> Ta bort </Button>
        <Button className='admin__main-button' onClick={showOrder}> Byt ordning </Button>
        <AdminEmployeesOrder closeModal={closeOrder} isOpen={orderOpen} initialData={employeeData} />
        </>
    );
};

export default AdminEmployees;