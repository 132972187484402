import React, {useState, useEffect} from 'react';
import axios from "axios";
// React components
import Landing from '../../landing/Landing';
import ShowMore from '../../landing/ShowMore';
import WorkForm from './WorkForm';
import WorkEntry from './WorkEntry';
// Styling
import './work.css';
import '../../body/body.css';
// Assets
import LandingBackground from '../../../assets/landing-work.jpg'; 

let text = require('./text.json');

/**
 * @brief Get a list of view components for available jobs
 * @param {*} workItems 
 * @param {*} handleSetJob 
 * @returns 
 */
function getAvailableWork(workItems, handleSetJob, language) {
    let availableWork = [];

    if (workItems == undefined || typeof(workItems) === 'string') return;
  
    for (let i = 0; i < workItems.length; i++) {
        // If we are on the english page - check whether swedish is a requirement -> if so, don't show it
        if (language === 'en') {
            if (!workItems[i].requirements.includes('Svenska') && !workItems[i].requirements.includes('svenska')) {
                availableWork.push(<WorkEntry language={language} data={workItems[i]} onSetJob={handleSetJob} key={workItems[i].title} />)
            }
        } else {
            availableWork.push(<WorkEntry language={language} data={workItems[i]} onSetJob={handleSetJob} key={workItems[i].title} />)
        }
    }

    if (availableWork.length === 0) {
        return <h1 className='h3'> {text[language]["jobs_no-jobs"]} </h1>
    }

    return availableWork;
}

const Work = ({ language }) => {
    const [availableWork, setavailableWork] = useState([]);
    const [defaultJob, setDefaultJob] = useState('default');

    const handleSetJob = (job) => { 
        setDefaultJob(job); 
    };

    
    
    // Fetch all available work
    useEffect(() => {
        const backendUrl = process.env.REACT_APP_BACKEND_PHP;

        axios.get(backendUrl + 'WORK/') 
            .then(response => {
                setavailableWork(response.data);
            })
            .catch(error => {
                console.log(error);
            });
        }, []);

    const LandingContent = () => (
        <>
            <div className='work__landing'>
                <div className='work__landing-info'>
                    <h2>{text[language]["jobs_info_title"]}</h2>
                    <p>{text[language]["jobs_info_text"]}</p>
                    <p><span>{text[language]["jobs_review-victor"]}</span> - Victor Hwasser</p>
                </div>
                <WorkForm language={language} defaultJob={defaultJob} availableWork={availableWork} />
            </div>
            <ShowMore scrollTo={"work__available"} text={text[language]['jobs_more']} />
        </>
    );

    return (
        <>
            <Landing language={language} content={LandingContent()} image={LandingBackground} />
            <div className='body__content'>
                
                <div className='work__availablework' id='work__available'>
                    <div className='work__landing-info-mobile'>
                        <h2>{text[language]["jobs_info_title"]}</h2>
                        <p>{text[language]["jobs_info_text"]}</p>
                    </div>
                    <h1> {text[language]['jobs_available']} </h1>
                    <div className='work__availablework-content'>
                        {getAvailableWork(availableWork, handleSetJob, language)}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Work;