import React from 'react';
import { NavLink } from 'react-router-dom';
// React components
import Landing from '../../landing/Landing'
import ShowMore from '../../landing/ShowMore';
import scrollIntoView from '../../landing/scrollIntoView';
import InformationTab from '../../companyInformation/CompanyInformation';
import OfferForm from './OfferForm';
import { offerFormAll } from '../../form/formOfferArrends'
// Styling
import './offer.css';
import '../../companyInformation/companyInformation.css'
// Assets
import ImageOfferService from '../../../assets/arbete.jpg'; 
import ImageOfferContracts from '../../../assets/ritning2.jpg'; 
import ImageOfferLightning1 from '../../../assets/belysningsprodukter1.jpg'; 
import ImageOfferLightning2 from '../../../assets/belysningsprodukter2.jpg'; 
import ImageOfferLightning3 from '../../../assets/belysningsprodukter3.jpg'; 
import ImageOfferLightning4 from '../../../assets/belysningsprodukter4.jpg'; 
import ImageOfferTechnical from '../../../assets/arbete2.jpg'; 

import LandingBackground from '../../../assets/landing-omoss.JPG'; 

let text = require('./text.json');

const Offer = ({ language }) => {
    const LandingContent = () => (
        <>
            <div className='offer__landing'>
                <div className='offer__landing-slogan'>
                    <p className='p1'>{text[language]["offer-landing_slogan1"].toUpperCase()}</p>
                    <h1 className='glowing'>{text[language]["offer-landing_slogan2"].toUpperCase()}</h1>
                </div>
                <p className='p1'>{text[language]["offer-landing_text"]}</p>
                <div className='offer__landing-buttons'>
                    <NavLink className='button button_hollow-white' onClick={() => scrollIntoView("offer__service")}>
                        {text[language]["offer-landing_button-read-more"].toUpperCase()}
                    </NavLink>
                    <NavLink className="button button_filled-black" onClick={() => scrollIntoView("offer__form")}>
                        {text[language]["offer-landing_button-form"].toUpperCase()}
                    </NavLink>
                </div>
            </div>
            <ShowMore scrollTo={"offer__service"} text={text[language]['offer-landing-more']} />
        </>
    );

    return (
        <>
        <Landing language={language} content={LandingContent()} image={LandingBackground} />
        <div className='body__content' >

            <div className='body__content-empty-space'></div>

            <InformationTab image={ImageOfferService} alt={"offer service"} id={"offer__service"} title={text[language]["offer-1-header"]} direction={"left"}
                text={[text[language]["offer-1-fulltext1"], text[language]["offer-1-fulltext2"], text[language]["offer-1-fulltext3"]]}/>

            <div className='body__content-empty-space'></div>
            <InformationTab image={ImageOfferContracts} alt={"offer contracts"} id={"offer__contracts"} title={text[language]["offer-2-header"]} direction={"right"}
                text={[text[language]["offer-2-fulltext1"], text[language]["offer-2-fulltext2"]]}/>

            <div className='body__content-empty-space'></div>
            <InformationTab image={ImageOfferTechnical} alt={"offer technical assistance"} id={"offer__tech"} title={text[language]["offer-4-header"]} direction={"left"}
                text={[text[language]["offer-4-fulltext1"], text[language]["offer-4-fulltext2"]]}/>

            <div className='body__content-empty-space'></div>
            <InformationTab image={[ImageOfferLightning1, ImageOfferLightning2, ImageOfferLightning3, ImageOfferLightning4]} 
                alt={"offer lightning products"} id={"offer__lights"} title={text[language]["offer-3-header"]} direction={"right"}
                text={[text[language]["offer-3-fulltext1"], text[language]["offer-3-fulltext2"]]}/>

            <div className='body__content-empty-space' id='offer__form'></div>

            <div className='contact__offer-form' id='offer_form'>
                <h1> Kontaktformulär </h1>
                <OfferForm language={language} category={offerFormAll[language]} landing={false} />
            </div>

            <div className='body__content-empty-space'></div>

        </div>
        </>
    );
}

export default Offer;

