import { React } from 'react';
import api from '../api';
import CRUDadd from '../CRUDadd';
import { Form, Input, Switch, Tooltip } from "antd";

const AdminCustomersPut = ({ isModalOpen, closeModal, onGetCurrentCustomers, onLogout }) => {

    const currentValue = onGetCurrentCustomers();
    
    async function submitData(values) {
        values.id = currentValue.id;
        const customersitem = JSON.stringify(values);
    
        api.put('CUSTOMERS/', customersitem)
          .then(response => {
            window.location.reload();
          })
          .catch(error => {
            console.error(error);
            onLogout();
          });
    } 

    const items = (
        <>
        <Form.Item name="name" label="Namn" initialValue={currentValue.name}
            rules={[{pattern: new RegExp(/^[ \-!?,.üÜåäöÅÄÖa-zA-Z0-9]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item>
        <Tooltip placement="bottom" title={"Län / Landskap eller t ex 'Entreprenader'"}>
            <Form.Item name="area" label="Område" initialValue={currentValue.area}
                rules={[{pattern: new RegExp(/^[ \-!?,.åäöÅÄÖa-zA-Z0-9]*$/),message: "Inga skumma symboler tack!"},
                    {required: true,message: "Detta fält behövs!",},]}>
                <Input />
            </Form.Item>
        </Tooltip>
        <Form.Item name="href" label="Länk-adress" initialValue={currentValue.href}
            rules={[{pattern: new RegExp(/^[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=]+$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item> 
        <Tooltip placement="bottom" title={"Huruvida kunden skall synnas under 'referenser' på 'Om Oss'-sidan."}>
            <Form.Item label="Visa i ref." name="visible" initialValue={currentValue.visible}>
            <Switch />
            </Form.Item>
        </Tooltip>
        </>
    )

    return (<CRUDadd isModalOpen={isModalOpen} closeModal={closeModal} handleSubmitData={submitData} title={"Redigera en nyhet"} items={items} />);
};

export default AdminCustomersPut;