import { React, useState } from 'react';
import api from '../api';
import CRUDadd from '../CRUDadd';
import { Form, Input, Upload, DatePicker, message } from "antd";
import { PlusOutlined } from '@ant-design/icons';
const { TextArea } = Input;

// Norm number of files
const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
};



const AdminNewsAdd = ({ isModalOpen, closeModal, onLogout }) => {
    const [fileList, setFileList] = useState([]);
    const [isValid, setIsValid] = useState(true);

    // Ignore early upload
    const handleUpload = () => { }
    
    // Input validation of image
    const uploadImageValidation = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Fil måste vara av typ JPG/PNG/GIF!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Bilden får max vara 2MB!');
        }
        setIsValid(isJpgOrPng && isLt2M);
        setFileList([...fileList, file]);
        return false;
    };

    async function submitData(values) {
        // Set date to MySQL-DATE format
        const dateYear = values.published["$y"];
        const dateMonth = values.published["$M"]+1;
        const dateDay = values.published["$D"];
        const date = `${dateYear}-${dateMonth}-${dateDay}`;

        // Check whether there is a valid file
        if (fileList.length === 0 || !isValid) return;
        // Make sure (again) the image is of the right file format
        if (values['file'][0].type === "image/png" || values['file'][0].type === "image/jpeg" ) {
            const formData = new FormData();

            // Append the field values
            for (const key in values) {
                if (values.hasOwnProperty(key) && key !== 'file' && key !== 'published') {
                    formData.append(key, values[key]);
                }
            }
            formData.append('published', date);
            formData.append('file', fileList[0]);


            api.post('/NEWS', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
            })
            .then(response => {
                console.log(response.data);
                window.location.reload();
            })
            .catch(error => {
                console.error(error);
                onLogout();
            });
        } else {
            console.log("Incorrect type!");
        }
    }

    const items = (
        <>
        <Form.Item name="title_sv" label="Titel (svenska)" 
            rules={[{pattern: new RegExp(/^[ \-!?':,.éÉüÜåäöÅÄÖa-zA-Z0-9]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item>
        <Form.Item name="title_en" label="Titel (engelska)"
            rules={[{pattern: new RegExp(/^[ \-!?':,.éÉüÜåäöÅÄÖa-zA-Z0-9]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item>
        <Form.Item name="text_sv" label="Text (svenska)"
            rules={[{pattern: new RegExp(/^[ \-!?':,.éÉüÜåäöÅÄÖa-zA-Z0-9\n]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="text_en" label="Text (engelska)"
            rules={[{pattern: new RegExp(/^[ \-!?':,.éÉüÜåäöÅÄÖa-zA-Z0-9\n]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <TextArea rows={4} />
        </Form.Item>
        <Form.Item 
            label="Publiceringsdatum:"
            name="published"
            rules={[{ required: true, message: 'Vänligen välj publiceringsdatum' }]}
        >
            <DatePicker />
        </Form.Item>
        <Form.Item name="file" label="Bild" valuePropName="fileList" getValueFromEvent={normFile}>
            <Upload 
                maxCount={1} 
                beforeUpload={uploadImageValidation} 
                listType="picture-card">
                <button
                style={{
                    border: 0,
                    background: 'none',
                }}
                type="button"
                onClick={handleUpload}
                >
                <PlusOutlined />
                <div
                    style={{
                    marginTop: 8,
                    }}
                >
                    Upload
                </div>
                </button>
            </Upload>
        </Form.Item>
        </>
    )

    return (<CRUDadd isModalOpen={isModalOpen} closeModal={closeModal} handleSubmitData={submitData} title={"Lägg till ny nyhet"} items={items} />);
};

export default AdminNewsAdd;