import React from 'react';
import { resetCookies } from '../../../functionality/cookies';
import Landing from '../../landing/Landing'
import "./policy.css";
// Assets
import LandingBackground from '../../../assets/landing-work.jpg'; 

let text = require('./text.json');

const removeCookies = () => {
    resetCookies();
    window.location.reload();
}


const Policy = ({ language }) => {
    const LandingContent = () => (
        <>
            <div className='policy__content'>
                <h1> {text[language]["policy-1-title"]} </h1>
                <p> {text[language]["policy-1-text1"]} </p>
                <p> {text[language]["policy-1-text2"]} </p>
        
                <h1> {text[language]["policy-2-title"]} </h1>
                <p> {text[language]["policy-2-text1"]} </p>

                <p> {text[language]["policy-2-types"]} </p>
                <ol>
                    <li>1. {text[language]["policy-2-types-1"]} </li>
                    <li>2. {text[language]["policy-2-types-2"]} </li>
                    <li>3. {text[language]["policy-2-types-3"]} </li>
                </ol>
        
                <h1> {text[language]["policy-3-title"]} </h1>
                <p> {text[language]["policy-3-text1"]} </p>
                <p> {text[language]["policy-3-text2"]} </p>
        
                <button className='button button_filled-black' onClick={() => removeCookies()}> 
                    {text[language]["policy-withdraw"]} 
                </button>
            </div>
        </>
    );

    return <Landing language={language} content={LandingContent()} image={LandingBackground} />
}



export default Policy;